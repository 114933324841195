import gsap from 'gsap';

// function getRange(val, extreme) {
//   if (Math.max(0, val) === 0) {
//     return 0;
//   }
//   else {
//     return Math.min(val, extreme);
//   }
// }

const hcBox = document.querySelector('.humane-computing-animation__mid .content');

window.addEventListener('mousemove', (event) => {
  const rect = hcBox.getBoundingClientRect();
  const mouseX = event.clientX - rect.left;
  const mouseY = event.clientY - rect.top;
  const adjustedWidth = 155;
  const adjustedHeight = 170;

  if (-adjustedWidth < mouseX && mouseX < rect.width + adjustedWidth &&
     -adjustedHeight < mouseY && mouseY < rect.height + adjustedHeight) {
    gsap.to('.spotlight .spot', {
      duration: 0.6,
      opacity: 1,
    });

    gsap.to('.spotlight .spot', {
      x: mouseX,
      y: mouseY,
      stagger: -0.05,
    });
  }
  else {
    gsap.to('.spotlight .spot', {
      opacity: 0,
      duration: 0.8,
    });
  }
});
