import Typed from 'typed.js';

// Wrap Cursor in trustedTypes to prevent XSS attacks
function generateTrustedInputs() {
  const cursor = '&vert;';

  if (window.trustedTypes?.createPolicy) {
    const TypedPolicy = window.trustedTypes.createPolicy('typed', {
      createHTML: s => s,
    });

    return {
      cursor: TypedPolicy.createHTML(cursor),
    };
  }
  else {
    return {
      cursor,
    };
  }
}


const { cursor } = generateTrustedInputs();

const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
const noTypewriter = document.querySelector('#no-typewriter-strings');

const typed = new Typed('#typewriter', {
  // important: Do not add strings here as they are invisible to subfont
  // strings: [
  //   'the Desktop',
  //   'the Browser',
  //   'Applications',
  //   'Computing!',
  // ],
  stringsElement: '#typewriter-strings',
  cursorChar: cursor,
  contentType: null, // important: Sidestep CSP violation by not writing to innerHTML
  autoInsertCss: false, // important: Avoid CSP violation by not adding inline style
  startDelay: 750,
  typeSpeed: 135,
  backSpeed: 75,
  backDelay: 1000,
  smartBackspace: false,
  preStringTyped(arrayPos, self) {
    // eslint-disable-next-line no-param-reassign
    self.el.style['font-weight'] = (arrayPos < self.strings.length - 1) ? '400' : '600';
  },
  onBegin() {
    noTypewriter.style.opacity = '0';
  },
  onReset() {
    noTypewriter.style.opacity = '0';
  },
  onDestroy() {
    noTypewriter.style.opacity = '1';
  },
  onComplete(self) {
    setTimeout(() => self.reset(true), 7500);
  },
});

// Stop ASAP if not necessary
if (mediaQuery.matches) {
  typed.destroy();
}

function toggleTyped() {
  if (mediaQuery.matches) {
    typed.destroy();
  }
  else {
    typed.reset(true);
  }
}

mediaQuery.addEventListener('change', toggleTyped);
